import botSrcSet from "./assets/blast_bot_teevee_sad-270-270.webp";
import classNames from "classnames";
import { Link } from "@remix-run/react";

const botPosition = {
  404: {
    zIndex: 10,
    top: "20px",
    left: "92px",
  },
  500: {
    top: "32px",
    left: "196px",
  },
};

const description = {
  404: "It looks like the page you're looking for doesn't exist. Don't worry, we've got plenty of other great content for you to explore.",
  500: "We're experiencing some technical difficulties right now. Please bear with us while we sort things out.",
};

const title = { 500: "Oops! Something went wrong", 404: "Oops! Page Not Found" };

export const ErrorScreen = ({ status }: { status: 404 | 500 }) => {
  return (
    <div className="m-nav flex justify-center">
      <div className="max-w-[550px]">
        <div className="min-h-fill-available flex flex-col items-center justify-center">
          <div className="relative">
            <h1
              className="font-medium uppercase"
              style={{
                fontFamily: "TT Norms Pro",
                fontSize: "150px",
                lineHeight: "110px",
              }}
            >
              {status}
            </h1>
            <img
              srcSet={botSrcSet}
              alt=""
              className={classNames("absolute object-cover", {
                "z-10": status === 404,
                "-z-10": status === 500,
              })}
              style={{
                width: "90px",
                height: "90px",
                ...botPosition[status],
              }}
            />
          </div>
          <p
            className="mt-5 text-center"
            style={{
              fontFamily: "TT Norms Pro",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "30px",
            }}
          >
            {title[status]}
          </p>
          {status === 500 && (
            <p
              className="text-foreground-90 mt-5 text-center"
              style={{
                fontFamily: "TT Norms Pro",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "30px",
              }}
            >
              Internal server error
            </p>
          )}

          <p className="font-style-body-b2 mt-9 text-center">{description[status]}</p>
          <p className="font-style-body-b2 mt-6 text-center">
            Head back to the{" "}
            <Link to="/" className="text-primary-100">
              Homepage
            </Link>{" "}
            and catch the latest updates.
          </p>
          <p className="font-style-body-b2 mt-6 text-center">
            If the problem persists, please{" "}
            <a href="mailto:support@blast.tv" className="text-primary-100">
              contact our support team.
            </a>{" "}
            Thanks for your patience!
          </p>
        </div>
      </div>
    </div>
  );
};
