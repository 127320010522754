import { z } from "zod";
import { AxiosError } from "axios";
import { captureException } from "@sentry/remix";
import { FormEvent, useEffect, useId, useMemo, useRef, useState } from "react";
import { useGoogleReCaptcha } from "@google-recaptcha/react";
import { Modal, Icon } from "@portal-frontend-ssr/ui";
import { getConfig } from "@portal-frontend-ssr/config";
import { getAnonymousFlags, LoginResult, RegisterResult } from "@portal-frontend-ssr/blast-api";
import { HeaderSection } from "./components/HeaderSection";
import { DescriptionSection } from "./components/DescriptionSection";
import { InfoTextSection } from "./components/InfoTextSection";
import { SubmitButton } from "./components/SubmitButton";
import { Link, useSearchParams } from "@remix-run/react";
import { getFederatedLoginErrorMessage } from "./components/getFederatedLoginErrorMessage";
import appleLogo from "./components/assets/apple_logo.svg";
import googleLogo from "./components/assets/google_logo.svg";
import { getTeeVeeState, TeeVeeAnimation } from "./components/TeeVeeAnimation";
import { AuthModalState, useAuthModal } from "../../AuthModalProvider";
import { useAuth } from "../../AuthProvider";

const AuthModal = ({ track }: { track?: (event: string, properties: any) => void }) => {
  const { closeModal, isModalOpen, modalState } = useAuthModal();
  const { login, register, requestResetPassword, resendVerificationEmail } = useAuth();
  const { executeV3 } = useGoogleReCaptcha();
  const [state, setState] = useState<AuthModalState>(modalState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOptIn, setIsOptIn] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [showThirdPartyOptions, setShowThirdPartyOptions] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const privacyCheckboxRef = useRef<HTMLInputElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [signUpError] = useState<string | null>(() => searchParams.get("sign-up-error"));

  useEffect(() => {
    const hasError = searchParams.get("sign-up-error");
    if (hasError) {
      track?.("Login Error Triggered", {
        context: `${["login", "register"].includes(state) ? state : "auth"}-dialog`,
        reason: hasError,
        success: false,
      });
      searchParams.delete("sign-up-error");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, state]);

  // TODO remove when 3rd_party_signon flag is removed
  useEffect(() => {
    getAnonymousFlags()
      .then((flags) => {
        const signOnFlag = flags.find((flag) => flag.feature.name === "3rd_party_signon");
        setShowThirdPartyOptions(signOnFlag?.enabled === true);
      })
      .catch((error) => {
        captureException(error);
      });
  });

  const handleLoginResult = (result: LoginResult) => {
    if (result === "success") {
      track?.("Login Attempted", {
        context: "login-dialog",
        success: true,
        attempts: loginAttempts,
      });
      setLoginAttempts(0);
      return;
    } else if (result === "wrong-credentials") {
      const message = "Incorrect email or password.";
      passwordInputRef.current?.setCustomValidity(message);
      emailInputRef.current?.setCustomValidity(message);
      formRef.current?.reportValidity();
      setShowError(true);
      track?.("Login Attempted", {
        context: "login-dialog",
        reason: "wrong-credentials",
        success: false,
        attempts: loginAttempts,
      });
    } else if (result === "user-third-party-register") {
      const message = "Registered with third-party provider. Use their login flow.";
      passwordInputRef.current?.setCustomValidity(message);
      emailInputRef.current?.setCustomValidity(message);
      formRef.current?.reportValidity();
      setShowError(true);
      track?.("Login Attempted", {
        context: "login-dialog",
        reason: "user-third-party-register",
        success: false,
        attempts: loginAttempts,
      });
    } else if (result === "not-verified") {
      setState("awaiting-verification-email");
      track?.("Login Attempted", {
        context: "login-dialog",
        reason: "not-verified",
        success: false,
        attempts: loginAttempts,
      });
    }
    setLoginAttempts(loginAttempts + 1);
  };

  const handleRegisterResult = (result: RegisterResult) => {
    switch (result) {
      case "success": {
        setState("awaiting-verification-email");
        return;
      }
      case "email-already-registered": {
        emailInputRef.current?.setCustomValidity("Email already exists, try logging in.");
        formRef.current?.reportValidity();
        break;
      }
      case "invalid-password": {
        passwordInputRef.current?.setCustomValidity("Password must be at least 8 characters long.");
        formRef.current?.reportValidity();
        break;
      }
      case "invalid-email": {
        emailInputRef.current?.setCustomValidity("The entered email is invalid.");
        formRef.current?.reportValidity();
      }
    }
  };

  const handleUnknownError = (error: unknown) => {
    if (error instanceof AxiosError) {
      captureException(error.toJSON());
    } else {
      captureException(error);
    }
    setShowError(true);
  };

  useEffect(() => {
    setState(modalState);
  }, [modalState]);

  useEffect(() => {
    setIsWaitingForResponse(false);
    emailInputRef.current?.setCustomValidity("");
    passwordInputRef.current?.setCustomValidity("");
  }, [state]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (formRef?.current?.reportValidity()) {
      if (["login", "first-login"].includes(state)) {
        setIsWaitingForResponse(true);

        login({
          login: email,
          password,
        })
          .then((result) => {
            handleLoginResult(result);
            setIsWaitingForResponse(false);
            if (result === "success") {
              closeModal();
            }
          })
          .catch((error) => {
            handleUnknownError(error);
            setIsWaitingForResponse(false);
          });
      } else if (state === "register") {
        let isInvalid = false;
        if (!z.string().email().safeParse(email).success) {
          handleRegisterResult("invalid-email");
          isInvalid = true;
        }

        if (password.length < 8) {
          handleRegisterResult("invalid-password");
          isInvalid = true;
        }
        if (isInvalid) {
          return;
        }

        setIsWaitingForResponse(true);

        const recaptchaToken = await executeV3?.("register");
        register({
          email,
          password,
          isOptIn,
          recaptchaToken,
        })
          .then((result) => {
            handleRegisterResult(result);
            setIsWaitingForResponse(false);
          })
          .catch((error: Error) => {
            handleUnknownError(error);
            setIsWaitingForResponse(false);
          });
      } else if (state === "forgot-password" || state === "reset-password-requested") {
        setIsWaitingForResponse(true);

        requestResetPassword({ email })
          .then(() => {
            setIsWaitingForResponse(false);
            setState("reset-password-requested");
          })
          .catch((error) => {
            handleUnknownError(error);
            setIsWaitingForResponse(false);
          });
      } else if (state === "ban") {
        closeModal();
      } else if (state === "awaiting-verification-email") {
        setIsWaitingForResponse(true);

        resendVerificationEmail({ email })
          .then(() => {
            setState("verification-email-resent");
          })
          .catch((error) => {
            handleUnknownError(error);
            setIsWaitingForResponse(false);
          });
      }
    }
  };

  const onDescriptionLinkClick = () => {
    if (state === "login") {
      setState("register");
    } else if (state === "register") {
      const enteredRegistrationData = !!emailInputRef.current?.value?.length;
      track?.("Login Button Clicked", {
        context: "register-dialog",
        enteredRegistrationData,
      });
      setState("login");
    } else if (state === "reset-password-requested") {
      setState("forgot-password");
    } else {
      setState("login");
    }
  };

  const isFormValid = useMemo(() => {
    // Correctly check validity between swithing between login and register
    if (state === "register") {
      if (!email) return false;
      if (!password) return false;

      return true;
    }

    if (state === "login") {
      if (!email) return false;
      if (!password) return false;

      return true;
    }

    if (state === "forgot-password") {
      if (!email) return false;

      return true;
    }

    return formRef.current?.checkValidity();
  }, [state, email, password]);

  const isSubmitDisabled = useMemo(() => {
    if (state === "verification-email-resent") return true;
    if (state === "ban") return true;
    if (isWaitingForResponse) return true;
    if (!isFormValid) return true;

    return false;
  }, [state, isWaitingForResponse, isFormValid]);

  // reset values when modal is opened
  useEffect(() => {
    setEmail("");
    setPassword("");
    setIsOptIn(false);
  }, [isModalOpen]);

  const emailInputId = useId();
  const passwordInputId = useId();
  const optInCheckboxId = useId();

  return (
    <Modal
      onClose={closeModal}
      isOpen={isModalOpen}
      className="bg-background-95 !m-0 flex !w-full max-w-lg flex-col items-center !px-4 !py-12"
    >
      <button onClick={closeModal} className="absolute right-6 top-6 max-md:right-4 max-md:top-4">
        <Icon icon="closeCircled" className="size-8" />
      </button>
      <form ref={formRef} onSubmit={handleSubmit} className="flex max-w-[410px] flex-col items-center gap-6">
        <TeeVeeAnimation state={getTeeVeeState(state)} showError={showError} />
        <HeaderSection state={state} />
        <DescriptionSection state={state} onLinkClick={onDescriptionLinkClick} />
        <div className="bg-foreground-95 mx-4 h-px w-full" />
        {["login", "register"].includes(state) && signUpError && (
          <label className="text-error font-style-body-b3 text-center">
            {getFederatedLoginErrorMessage(signUpError)}
          </label>
        )}
        <InfoTextSection state={state} />
        {state !== "reset-password-requested" &&
          state !== "awaiting-verification-email" &&
          state !== "verification-email-resent" && (
            <div className="flex w-full flex-col items-stretch gap-2">
              {["login", "first-login", "register", "forgot-password"].includes(state) && (
                <>
                  <label htmlFor={emailInputId} className="font-style-body-b2 block w-fit">
                    Email Address
                  </label>
                  <input
                    className="bg-background-100 font-style-body-b2 w-full p-3"
                    id={emailInputId}
                    ref={emailInputRef}
                    type={state === "login" ? "text" : "email"}
                    placeholder={"Enter your email address"}
                    value={email}
                    onChange={(event) => {
                      emailInputRef.current?.setCustomValidity("");
                      passwordInputRef.current?.setCustomValidity("");
                      setEmail(event.target.value);
                      setShowError(false);
                    }}
                    required
                    autoCapitalize="off"
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && emailInputRef.current?.reportValidity()) {
                        passwordInputRef.current?.focus();
                      }
                    }}
                    onFocus={() => {
                      emailInputRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  />
                </>
              )}
              {["register", "login", "first-login"].includes(state) && (
                <>
                  <div className="flex w-full items-center justify-between">
                    <label htmlFor={passwordInputId} className="font-style-body-b2">
                      Password
                    </label>
                    {["login", "first-login"].includes(state) && (
                      <button onClick={() => setState("forgot-password")} className="button button-tertiary">
                        Forgot password?
                      </button>
                    )}
                  </div>
                  <input
                    className="bg-background-100 font-style-body-b2 w-full p-3"
                    id={passwordInputId}
                    ref={passwordInputRef}
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(event) => {
                      emailInputRef.current?.setCustomValidity("");
                      passwordInputRef.current?.setCustomValidity("");
                      setPassword(event.target.value);
                      setShowError(false);
                    }}
                    required
                    autoCapitalize="off"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        if (passwordInputRef.current?.reportValidity()) {
                          if (state === "register") {
                            event.preventDefault();
                            privacyCheckboxRef.current?.focus();
                          } else {
                            void handleSubmit(event);
                          }
                        }
                      }
                    }}
                  />
                </>
              )}
            </div>
          )}
        {state === "register" && (
          <div className="flex items-start gap-3 text-left">
            <input onChange={(event) => setIsOptIn(event.target.checked)} type="checkbox" id={optInCheckboxId} />
            <label htmlFor={optInCheckboxId} className="font-style-body-b2">
              I want access to the latest and greatest news on Dota, CS and BLAST.tv as well as pre-sale tickets and
              exclusive community perks!
            </label>
          </div>
        )}
        <SubmitButton state={state} isDisabled={isSubmitDisabled} />
        {state === "register" && (
          <div className="font-style-body-b2 px-10 text-center">
            <span>{"By continuing, you're agreeing to our "}</span>
            <Link to="/privacy-policy" className="no-underline" target="_blank">
              <span className="text-primary-100 text-center">{" Terms of Use and Privacy policy."}</span>
            </Link>
          </div>
        )}
        {showThirdPartyOptions && ["login", "register"].includes(state) && (
          <div className="flex w-full flex-col">
            <div className="mb-5 flex flex-row items-center">
              <div className="bg-foreground-95 h-px w-full" />
              <label className="text-foreground-90 font-style-body-b2 mx-3 content-center whitespace-nowrap">
                or {state === "register" ? " register with" : " login with"}
              </label>
              <div className="bg-foreground-95 h-px w-full" />
            </div>
            <div className="flex w-full flex-row justify-center gap-8">
              <a
                className="hover:bg-foreground-95 grid size-16 place-items-center rounded bg-white"
                href={`${getConfig().API_BASE_URL}/v1/auth/providers/authorize?redirectUri=${location.href}&provider=google`}
                rel="noreferrer"
              >
                <img src={googleLogo} alt="google" />
              </a>
              <a
                className="hover:bg-foreground-95 grid size-16 place-items-center rounded bg-white"
                href={`${getConfig().API_BASE_URL}/v1/auth/providers/authorize?redirectUri=${location.href}&provider=apple`}
                rel="noreferrer"
              >
                <img src={appleLogo} alt="apple" />
              </a>
            </div>
          </div>
        )}
        {state === "login" && showError && (
          <div className="text-error font-style-body-b3 mb-4 text-left">
            You whiffed that shot.. Check your email and password and try again!
          </div>
        )}
        {state === "forgot-password" && (
          <button onClick={() => setState("login")} className="button button-tertiary">
            Cancel
          </button>
        )}
        {["awaiting-verification-email", "verification-email-resent", "reset-password-requested"].includes(state) && (
          <div className="font-style-body-b2 text-center text-neutral">
            If you are experiencing problems, please contact us at support@blast.tv
          </div>
        )}
      </form>
    </Modal>
  );
};

export { AuthModal };
