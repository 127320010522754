import { useMemo } from "react";
import { AuthModalState } from "../../../AuthModalProvider";

export const SubmitButton = ({ state, isDisabled }: { state: AuthModalState; isDisabled: boolean }) => {
  const text = useMemo(() => {
    switch (state) {
      case "login":
      case "first-login":
        return "Log in";
      case "register":
        return "Join the community";
      case "forgot-password":
        return "Send Link";
      case "ban":
        return "Okay";
      case "reset-password-requested":
        return "Resend";
      case "awaiting-verification-email":
      case "verification-email-resent":
        return "Resend verification";
      default:
        return "";
    }
  }, [state]);

  if (!text) return null;

  return (
    <button disabled={isDisabled} type="submit" className="button w-full">
      {text}
    </button>
  );
};
