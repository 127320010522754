export const getFederatedLoginErrorMessage = (error: string): string => {
  switch (error) {
    case "email-already-exists":
      return "You're already a BLAST.tv member, please try and login using the same method you registered with.";

    case "email-domain-not-allowed":
      return "This email domain is not allowed.";

    case "user-cancelled-authorize":
      return "You seem to have cancelled the authentication process. Please try again.";

    default:
      return "An unknown error has occurred, please try again. If this continues, please email support@blast.tv.";
  }
};
