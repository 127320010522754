import { AuthModalState } from "../../../AuthModalProvider";

export const DescriptionSection = ({ state, onLinkClick }: { state: AuthModalState; onLinkClick: () => void }) => {
  switch (state) {
    case "login":
      return (
        <div className="font-style-body-b2">
          <span className="text-neutral">{"Don't have an account yet?"}</span>
          <button onClick={onLinkClick} className="button button-tertiary ml-2 inline">
            Join the community
          </button>
        </div>
      );

    case "register":
      return (
        <div className="font-style-body-b2">
          <span className="text-neutral">Already a BLAST.tv member?</span>
          <button onClick={onLinkClick} className="button button-tertiary ml-2 inline">
            Log in
          </button>
        </div>
      );

    default:
      return null;
  }
};
