import { useMemo } from "react";
import { AuthModalState } from "../../../AuthModalProvider";

export const HeaderSection = ({ state }: { state: AuthModalState }) => {
  const text = useMemo(() => {
    switch (state) {
      case "login":
        return <>Log in to your account</>;
      case "first-login":
        return <>Thanks for signing up! Please log in to complete your profile!</>;
      case "register":
        return <>Join the BLAST.tv community</>;
      case "forgot-password":
      case "reset-password-requested":
        return <>Forgot your password?</>;
      case "awaiting-verification-email":
      case "verification-email-resent":
        return <>{"You're almost in!"}</>;
      case "ban":
        return <>This account is permanently banned.</>;
      default:
        return <></>;
    }
  }, [state]);

  if (!text) return null;

  return <div className="font-style-heading-h2 max-md:font-style-heading-h3">{text}</div>;
};
