import { LinkDescriptor } from "@remix-run/node";
import { useMatches } from "@remix-run/react";
import { DynamicLinksFunction } from "./types";

export function DynamicLinks() {
  const links: LinkDescriptor[] = useMatches().flatMap((match) => {
    if (
      !(match.handle instanceof Object) ||
      !("dynamicLinks" in match.handle) ||
      !match.handle.dynamicLinks ||
      typeof match.handle.dynamicLinks !== "function"
    ) {
      return [];
    }

    const fn = match.handle.dynamicLinks as DynamicLinksFunction;
    return fn({ data: match.data });
  });

  return (
    <>
      {links.map((link) => (
        <link {...link} key={link.integrity || JSON.stringify(link)} />
      ))}
    </>
  );
}
