import teeveeRollIn from "./assets/teevee-roll-in.webm";
import teeveeDots from "./assets/teevee-dots.webm";
import teeveeHappy from "./assets/teevee-happy.webm";
import teeveeVerify from "./assets/teevee-verify.webm";
import teeveeCross from "./assets/teevee-cross.webm";
import { useEffect, useRef, useState } from "react";
import { useBreakpoints } from "@portal-frontend-ssr/ui";
import classNames from "classnames";
import { AuthModalState } from "../../../../AuthModalProvider";

export const getTeeVeeState = (state: AuthModalState): TeeVeeState | undefined => {
  switch (state) {
    case "register":
      return "roll-in";

    case "awaiting-verification-email":
      return "verify";

    default:
      return undefined;
  }
};

export type TeeVeeState = "roll-in" | "happy" | "dots" | "verify";

interface TeeVeeAnimationProps {
  state?: TeeVeeState;
  showError?: boolean;
}

function TeeVeeAnimation({ state: initialState, showError }: TeeVeeAnimationProps) {
  const [state, setState] = useState<TeeVeeState>(initialState ?? "roll-in");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onEnded = () => {
    if (showError) {
      return;
    }

    switch (state) {
      case "roll-in":
        setState("happy");
        break;

      case "happy":
        setState("dots");
        break;

      case "dots":
        videoRef.current?.play().catch(() => {
          // play was prevented
        });
        break;

      case "verify":
        setIsCollapsed(true);
        break;
    }
  };

  const isMobile = useBreakpoints().isMobile;

  useEffect(() => {
    if (initialState === "verify") {
      setState("verify");
    }
  }, [initialState]);

  if (isMobile || !initialState) {
    return null;
  }

  return (
    <div
      className={classNames("flex h-32 items-center justify-center transition-all duration-300 ease-in-out", {
        "h-0": isCollapsed,
      })}
    >
      <video
        className="mx-0 my-auto"
        ref={videoRef}
        key={`${state}-${showError}`}
        autoPlay
        muted
        width={100}
        onEnded={onEnded}
      >
        {showError ? (
          <source src={teeveeCross} type="video/webm" />
        ) : (
          <>
            {state === "roll-in" && <source src={teeveeRollIn} type="video/webm" />}
            {state === "happy" && <source src={teeveeHappy} type="video/webm" />}
            {state === "dots" && <source src={teeveeDots} type="video/webm" />}
            {state === "verify" && <source src={teeveeVerify} type="video/webm" />}
          </>
        )}
      </video>
    </div>
  );
}

export { TeeVeeAnimation };
