import { AuthModalState } from "../../../AuthModalProvider";

export const InfoTextSection = ({ state }: { state: AuthModalState }): JSX.Element | null => {
  switch (state) {
    case "forgot-password":
      return (
        <div className="font-style-body-b2 text-left">
          {`Don't worry! Even the best of us forget. Get a link to reset your
              password by entering the email address you used when registering on
              BLAST.tv.`}
        </div>
      );

    case "reset-password-requested":
      return (
        <>
          <div className="font-style-body-b2">
            {`Thanks! If the email address is registered on BLAST.tv, you'll receive an email with a link to reset your password shortly.`}
          </div>
          <div className="font-style-body-b2">{"Didn't receive an email and checked your spam?"}</div>
        </>
      );

    case "awaiting-verification-email":
      return (
        <div className="font-style-body-b2 text-center">
          {`We've sent a verification email to you. You know the drill, click the link in the email to verify your account and start enjoying BLAST.tv asap!`}
        </div>
      );

    case "verification-email-resent":
      return (
        <div className="font-style-body-b2 text-center">
          {`We've sent a verification email to you. You know the drill, click the link in the email to verify your account and start enjoying BLAST.tv asap!`}
        </div>
      );

    case "ban":
      return <div className="font-style-body-b2">If you have questions, contact us at support@blast.tv</div>;

    default:
      return null;
  }
};
