import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { ReactNode, useEffect } from "react";
import {
  isRouteErrorResponse,
  json,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { LinksFunction, HeadersFunction, LoaderFunctionArgs } from "@remix-run/node";
import { GoogleReCaptchaProvider } from "@google-recaptcha/react";
import { BusyIndicator, ErrorScreen } from "@portal-frontend-ssr/ui";
import { getUserProfile } from "@portal-frontend-ssr/blast-api";
import { FlagProvider, GameIdProvider } from "@portal-frontend-ssr/providers";
import { AuthModalProvider, AuthProvider, AuthModal, AvatarModalProvider } from "@portal-frontend-ssr/auth";
import { getConfig } from "@portal-frontend-ssr/config";
import { getFlags } from "@portal-frontend-ssr/blast-api";
import { analytics } from "@portal-frontend-ssr/analytics";
import tailwindStylesheet from "./tailwind.css?url";
import fontStyleSheet from "./styles/fonts.css?url";
import uiStyleSheet from "@portal-frontend-ssr/ui/src/styles/index.css?url";
import { AuthService } from "~/services/auth.server";
import { ReactQueryProvider } from "~/providers/ReactQueryProvider";
import { AlertProvider } from "~/providers/AlertProvider";
import { DynamicLinks } from "~/utils/DynamicLinks";
import { defaultTo } from "./shared/helpers/defaultTo";
import { generateMetaTags } from "./shared/helpers/metaTags/generateMetaTags";

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <ErrorScreen status={404} />;
    }

    if (error.status === 500) {
      return <ErrorScreen status={500} />;
    }
  } else if (error instanceof Error) {
    return <ErrorScreen status={500} />;
  } else {
    return (
      <div className="m-nav flex justify-center">
        <div className="max-width-section">
          <div className="flex flex-col justify-center gap-2">
            <h1 className="font-style-title-t1">Unknown Error</h1>
            <Link to="/">Go to Home</Link>
          </div>
        </div>
      </div>
    );
  }
}

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="en" className="custom-scrollbar">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="color-scheme" content="light dark" />
        <meta name="theme-color" content="#000000" />

        <script type="text/javascript" src="/ssr-public/cookies.js" />
        <script type="text/javascript" src="/ssr-public/ads.js" async />
        <script type="text/javascript" src="/ssr-public/hotjar.js" async />
        <script type="text/javascript" src="/ssr-public/gtag.js" defer />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              name: "BLAST.tv",
              url: "https://blast.tv/",
            }),
          }}
        />

        <Meta />
        <Links />
        <DynamicLinks />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: tailwindStylesheet },
    { rel: "stylesheet", href: fontStyleSheet },
    { rel: "stylesheet", href: uiStyleSheet },
    {
      rel: "icon",
      href: "/ssr-public/favicon.ico",
    },
    {
      rel: "manifest",
      href: "/ssr-public/manifest.json",
    },
    {
      rel: "preconnect",
      href: "https://consentcdn.cookiebot.com",
    },
    {
      rel: "preconnect",
      href: "https://script.hotjar.com",
    },
    {
      rel: "preconnect",
      href: "https://static.hotjar.com",
    },
    {
      rel: "preconnect",
      href: "https://cdn.rudderlabs.com",
    },
  ];
};

export const meta = () => {
  return generateMetaTags({});
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const authService = new AuthService(request);

  const newTokens = await authService.getNewTokens();
  const accessToken = newTokens?.accessToken ?? authService.accessTokenFromCookie;
  const userId = authService.tokenToJson(newTokens?.accessToken)?.username ?? authService.userId;

  const userProfile = await getUserProfile(userId, accessToken);

  const auth = {
    userId,
    userProfile,
  };

  let headers: undefined | Headers = undefined;
  if (newTokens) {
    headers = new Headers();
    for (const cookie of newTokens.cookies) {
      headers.append("Set-Cookie", cookie);
    }
  }

  const isProfileMissing = !!userId && !userProfile;
  if (isProfileMissing) {
    headers ??= new Headers();
    headers.append("Location", "/sign-up");
  }

  const flags = await getFlags({
    userId: auth.userId,
    email: userProfile?.email,
  }).catch(defaultTo<Record<string, boolean>>({}));

  const env = getConfig();
  env.DEADLOCK_SERVER_TOKEN = "REDACTED";
  env.RECAPTCHA_SECRET_KEY = "REDACTED";

  return json(
    {
      auth,
      flags,
      env,
    },
    {
      headers,
      status: isProfileMissing ? 302 : 200,
    },
  );
};

// the default header used when no nested route provides headers
// also used by 404 and 500 routes
export const headers: HeadersFunction = () => {
  return {
    "Cache-Control": ["max-age=0", "s-maxage=0"].join(", "),
  };
};

function App() {
  const { auth, env, flags } = useLoaderData<typeof loader>();

  // FIXME: This is a temporary solution to load the cookiebot script and not break hydration
  // This should be removed once we have a better solution
  useEffect(() => {
    if (document.querySelector("#Cookiebot")) {
      return;
    }

    const script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.dataset.cbid = "b0e99bea-bcb2-4e80-ad13-970256fa5822";
    script.dataset.framework = "TCFv2.2";
    script.type = "text/javascript";
    document.querySelector("script")?.before(script);
  }, []);

  return (
    <FlagProvider value={flags}>
      <GameIdProvider>
        <ReactQueryProvider>
          <AuthProvider {...auth}>
            <AlertProvider>
              <AuthModalProvider track={analytics.track}>
                <GoogleReCaptchaProvider type={"v3"} siteKey={env.RECAPTCHA_SITE_KEY} explicit={{ badge: "hidden" }}>
                  <AvatarModalProvider>
                    <AuthModal track={analytics.track} />
                    <BusyIndicator />
                    <Outlet />
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `window.ENV = ${JSON.stringify(env)}`,
                      }}
                    />
                  </AvatarModalProvider>
                </GoogleReCaptchaProvider>
              </AuthModalProvider>
            </AlertProvider>
          </AuthProvider>
        </ReactQueryProvider>
      </GameIdProvider>
    </FlagProvider>
  );
}

export default withSentry(App);
